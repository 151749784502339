export const MEDICINE_FETCH_MEDICINES_REQUEST = 'MEDICINE_FETCH_MEDICINES_REQUEST';
export const MEDICINE_FETCH_MEDICINES_SUCCESS = 'MEDICINE_FETCH_MEDICINES_SUCCESS';
export const MEDICINE_FETCH_MEDICINES_FAILURE = 'MEDICINE_FETCH_MEDICINES_FAILURE';

export const MEDICINE_ADD_REQUEST = 'MEDICINE_ADD_REQUEST';
export const MEDICINE_ADD_SUCCESS = 'MEDICINE_ADD_SUCCESS';
export const MEDICINE_ADD_FAILURE = 'MEDICINE_ADD_FAILURE';
export const MEDICINE_ADD_RESET = 'MEDICINE_ADD_RESET';

export const MEDICINE_UPDATE_REQUEST = 'MEDICINE_UPDATE_REQUEST';
export const MEDICINE_UPDATE_SUCCESS = 'MEDICINE_UPDATE_SUCCESS';
export const MEDICINE_UPDATE_FAILURE = 'MEDICINE_UPDATE_FAILURE';
export const MEDICINE_UPDATE_RESET = 'MEDICINE_UPDATE_RESET';

export const MEDICINE_ORDER_REQUEST = 'MEDICINE_ORDER_REQUEST';
export const MEDICINE_ORDER_SUCCESS = 'MEDICINE_ORDER_SUCCESS';
export const MEDICINE_ORDER_FAILURE = 'MEDICINE_ORDER_FAILURE';
export const MEDICINE_ORDER_RESET = 'MEDICINE_ORDER_RESET';

export const MEDICINE_REMOVE_REQUEST = 'MEDICINE_REMOVE_REQUEST';
export const MEDICINE_REMOVE_SUCCESS = 'MEDICINE_REMOVE_SUCCESS';
export const MEDICINE_REMOVE_FAILURE = 'MEDICINE_REMOVE_FAILURE';
export const MEDICINE_REMOVE_RESET = 'MEDICINE_REMOVE_RESET';

export const MEDICINE_SELECT_MEDICINE = 'MEDICINE_SELECT_MEDICINE';

export const MEDICINE_SET_MANAGEMENT_MODE = 'MEDICINE_SET_MANAGEMENT_MODE';

export const MEDICINE_FETCH_SELECTED_MEDICINE_REQUEST = 'MEDICINE_FETCH_SELECTED_MEDICINE_REQUEST';
export const MEDICINE_FETCH_SELECTED_MEDICINE_SUCCESS = 'MEDICINE_FETCH_SELECTED_MEDICINE_SUCCESS';
export const MEDICINE_FETCH_SELECTED_MEDICINE_FAILURE = 'MEDICINE_FETCH_SELECTED_MEDICINE_FAILURE';